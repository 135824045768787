import axios from "axios";
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPay4ZipURL = process.env.REACT_APP_PAY4ZIP_URL;

export const signUp = async (userName, email, password, isVip, license_key) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/users`, {
      userName: userName,
      email: email,
      password: password,
      isVip: isVip,
      license_key: license_key,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const checkIfVip = async (lisence_key) => {
  let res = null;
  try {
    res = await axios.get(`${serverPay4ZipURL}/license/${lisence_key}`);
    return res;
  } catch (err) {
    console.log("err", err.response);
    return err.response;
  }
};

export const updateTheCard = async (
  userId,
  token,
  card,
  brand,
  last4,
  exp_month,
  exp_year
) => {
  let res = null;
  try {
    res = await axios.put(`${serverURL}/slug/updateCard/${userId}`, {
      token: token,
      card: card,
      brand: brand,
      last4: last4,
      exp_month: exp_month,
      exp_year: exp_year,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const countriesData = async (email, password) => {
  let res = null;
  try {
    res = await axios.get(
      `https://trial.mobiscroll.com/content/countries.json`
    );
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const signIn = async (email, password) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/sign-in`, {
      email: email,
      password: password,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const checkAuthenticity = async (token) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/users/check`, {
      token: token,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const userProfile = async (token) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/users/profile`, {
      token: token,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const requestRecoverPassword = async (email) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/auth/request-password-reset`, {
      email: email,
    });
    return res;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

export const logOut = async (userId) => {
  let res = null;
  try {
    res = await axios.get(`${serverURL}/users/logout/${userId}`);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateThePassword = async (
  token,
  currentPassword,
  newPassword
) => {
  let res = null;
  try {
    res = await axios.put(`${serverURL}/users/update-password`, {
      token: token,
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const confirmTheAccount = async (token) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/users/confirm-email`, {
      token: token,
    });
    return res;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

export const setNewPassword = async (token, password) => {
  let res = null;
  try {
    res = await axios.put(`${serverURL}/auth/reset-password`, {
      token: token,
      password: password,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateTheProfile = async (token, userName) => {
  let res = null;
  try {
    res = await axios.put(`${serverURL}/users/update-profile`, {
      token: token,
      userName: userName,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateTheProfileWithAvatar = async (token, userName, avatar) => {
  let res = null;
  try {
    res = await axios.put(`${serverURL}/users/update-profile`, {
      token: token,
      userName: userName,
      avatar: avatar,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAvatar = async (avatar) => {
  let res = null;
  try {
    res = await axios.get(`${serverURL}/file/${avatar}`);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPublicDomains = async (domainType) => {
  let res = null;
  try {
    res = await axios.get(`${serverURL}/domain/public/${domainType}`);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getDedicatedDomain = async (dedicatedTo, domainType) => {
  let res = null;
  try {
    res = await axios.get(
      `${serverURL}/domain/self/${dedicatedTo}/${domainType}`
    );
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPublicDomainSlugStatus = async (domain, slug) => {
  let res = null;
  try {
    console.log(`${serverURL}/slug/status/${domain}/${slug}`);
    res = await axios.get(`${serverURL}/slug/status/${domain}/${slug}`);
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const paymentHistory = async (userId) => {
  let res = null;
  try {
    res = await axios.get(`${serverURL}/purchase/history/${userId}`);
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const deleteUser = async (id) => {
  let res = null;
  try {
    res = await axios.delete(`${serverURL}/users/self/${id}`);
    return res;
  } catch (err) {
    //res = err.response;
    // console.log("err", err.response);
    // return err.response;
  }
};

export const paymentCard = async (userId) => {
  let res = null;
  try {
    res = await axios.get(`${serverURL}/card/${userId}`);
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const checkIfAllowed = async (userId) => {
  let res = null;
  try {
    res = await axios.get(`${serverURL}/users/allowed/${userId}`);
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const cancelTheSubscription = async (subsriptionId, userId) => {
  let res = null;
  try {
    res = await axios.get(
      `${serverURL}/slug/cancelSub/${subsriptionId}/${userId}`
    );
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const deleteSlugfile = async (id) => {
  let res = null;
  try {
    res = await axios.delete(`${serverURL}/slug/file/${id}`);
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const payForPlans = async (
  subsriptionId,
  customerId,
  userId,
  token,
  card,
  fullName,
  companyName,
  vat,
  country,
  line1,
  city,
  zip,
  phone,
  price,
  line2,
  email,
  state
) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/slug/collectpayment`, {
      subsriptionId: subsriptionId,
      customerId: customerId,
      userId: userId,
      token: token,
      card: card,
      fullName: fullName,
      companyName: companyName,
      vat: vat,
      country: country,
      line1: line1,
      city: city,
      zip: zip,
      phone: phone,
      price: price,
      line2: line2,
      email: email,
      state: state,
    });
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const addZipFile = async (registeredBy, domain, slug, file) => {
  let res = null;
  try {
    res = await axios.post(`${serverURL}/slug/file/add`, {
      registeredBy: registeredBy,
      domain: domain,
      slug: slug,
      file: file,
    });
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const getZipFiles = async (userId) => {
  let res = null;
  try {
    res = await axios.get(`${serverURL}/slug/file/${userId}`);
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};

export const updateZipFile = async (id, file) => {
  let res = null;
  try {
    res = await axios.put(`${serverURL}/slug/file/${id}`, {
      file: file,
      isReSubmitted: true,
      isLive: false,
      latestUpdateAt: new Date(),
      flag: "newUpdate",
    });
    return res;
  } catch (err) {
    console.log("error", err);
    return err.response;
  }
};
