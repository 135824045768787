import React, { useEffect, Suspense, lazy, useTransition } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Outlet,
  Link,
  Routes,
} from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./pages/auth/privacy-policy";
import "./app.css";
// const ProtectedRoutes = lazy(() => import('./routesRoles/privateRoutes'))
// const AuthRoutes = lazy(() => import('./routesRoles/publicRoutes'))

//Routes Roles
const ProtectedRoutes = lazy(() => import("./routesRoles/protectedRoutes"));
const AuthRoutes = lazy(() => import("./routesRoles/authRoutes"));
//Public

const RecoverPassword = lazy(() =>
  import("./pages/auth/forgotPasswordRequest")
);

const ConfrimScreen = lazy(() => import("./pages/auth/cofirmationScreen"));
const Landing = lazy(() => import("./pages/public/landing"));
const Shahzad = lazy(() => import("./pages/public/shahzad"));
const AboutUs = lazy(() => import("./pages/public/aboutus"));
const HowItWorks = lazy(() => import("./pages/public/howItWokrs"));
const ReferralProgram = lazy(() => import("./pages/public/referralProgram"));
const FAQS = lazy(() => import("./pages/public/faq"));
const Privacy = lazy(() => import("./pages/public/privacy"));
const PaymentMethod = lazy(() => import("./pages/private/paymentmethod"));
const PaymentPlan = lazy(() => import("./pages/private/paymentplan"));
const Notification = lazy(() => import("./pages/private/notification"));
const Support = lazy(() => import("./pages/private/support"));
const AddSlug = lazy(() => import("./pages/private/addSlug"));
const Slugs = lazy(() => import("./pages/private/slugs"));
const TOS = lazy(() => import("./pages/public/tos"));
const ContactUs = lazy(() => import("./pages/public/contactus"));
const CookiePolicy = lazy(() => import("./pages/public/cookiePolicy"));
const AcceptableUsePolicy = lazy(() =>
  import("./pages/public/acceptableusepolicy")
);

const NewPassword = lazy(() => import("./pages/auth/recovernow"));
// //auth
const Login = lazy(() => import("./pages/auth/login"));
const SignUp = lazy(() => import("./pages/auth/signup"));
const Vip = lazy(() => import("./pages/auth/signupvip"));
const ForgotPassword = lazy(() => import("./pages/auth/forgotPassword"));

//Loader
const Loader = lazy(() => import("./components/common/loader"));
// //Private

const Home = lazy(() => import("./pages/private/home"));
const DepositList = lazy(() => import("./pages/private/depositList"));
const Withdraw = lazy(() => import("./pages/private/withdraw"));
const Payment = lazy(() => import("./pages/private/payment"));
const History = lazy(() => import("./pages/private/history"));
const MyAccount = lazy(() => import("./pages/private/myAccount"));
const Referral = lazy(() => import("./pages/private/referral"));

const App = () => {
  return (
    <>
      <Router>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          pauseOnVisibilityChange={false}
        />
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 36,
                fontVariant: "oldstyle-nums",
              }}
            >
              {/* <Loader /> */}
              Please wait...
            </div>
          }
        >
          <Switch>
            <AuthRoutes exact path="/">
              <Landing />
            </AuthRoutes>
            <AuthRoutes exact path="/shahzad">
              <Shahzad />
            </AuthRoutes>

   

            <AuthRoutes exact path="/recover-password-request">
              <RecoverPassword />
            </AuthRoutes>
            <AuthRoutes exact path="/confirmation">
              <ConfrimScreen />
            </AuthRoutes>

            <AuthRoutes exact path="/newpassword">
              <NewPassword />
            </AuthRoutes>

            <AuthRoutes exact path="/about-us">
              <AboutUs />
            </AuthRoutes>
            <AuthRoutes exact path="/how-it-works">
              <HowItWorks />
            </AuthRoutes>
            <AuthRoutes exact path="/referral-program">
              <ReferralProgram />
            </AuthRoutes>
            <AuthRoutes exact path="/faqs">
              <FAQS />
            </AuthRoutes>
            <AuthRoutes exact path="/contact-us">
              <ContactUs />
            </AuthRoutes>

            <Route exact path="/terms-of-use">
              <TOS />
            </Route>

            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>

            <Route exact path="/cookie-policy">
              <CookiePolicy />
            </Route>

            <Route exact path="/acceptable-use-policy">
              <AcceptableUsePolicy />
            </Route>

            <ProtectedRoutes exact path="/privacy">
              <Privacy />
            </ProtectedRoutes>
            <AuthRoutes exact path="/login">
              <Login />
            </AuthRoutes>
            <AuthRoutes exact path="/sign-up">
              <SignUp />
            </AuthRoutes>
            <AuthRoutes exact path="/vip">
              <Vip />
            </AuthRoutes>

            <ProtectedRoutes exact path="/home">
              <Home />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/change-password">
              <ForgotPassword />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/payment">
              <Payment />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/payment-method">
              <PaymentMethod />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/support">
              <Support />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/newlink">
              <AddSlug />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/slugs">
              <Slugs />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/withdraw">
              <Withdraw />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/payment-history">
              <History />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/payment-plan">
              <PaymentPlan />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/deposit-list">
              <DepositList />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/referral">
              <Referral />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/my-account">
              <MyAccount />
            </ProtectedRoutes>

            <ProtectedRoutes exact path="/notification">
              <Notification />
            </ProtectedRoutes>

            <Route
              path="*"
              component={() => (
                <div>
                  <h1 style={{ textAlign: "center" }}>
                    404 - Not Found!
                    <br />
                    {/* <Link to="/">Go Home</Link> */}
                  </h1>
                </div>
              )}
            />
          </Switch>
        </Suspense>
      </Router>
    </>
  );

  // return (
  //   <>
  //     <ToastContainer
  //       position="bottom-right"
  //       autoClose={2500}
  //       newestOnTop={false}
  //       rtl={false}
  //       pauseOnVisibilityChange={false}
  //     />

  //     <Router>
  //       <Suspense fallback={<div>loading...</div>}>
  //         <Routes>
  //           <PublicRoute
  //             path="/"
  //           >
  //             <Landing />
  //           </PublicRoute>

  //           <PrivateRoute
  //             path="/dashboard"

  //           >
  //             <Dashboard />
  //           </PrivateRoute>
  //           <Route path="*">
  //             <NoMatch />
  //           </Route>
  //         </Routes>
  //       </Suspense>
  //     </Router>

  //   </>
  // );

  // return (
  //   <Routes>
  //     <Route exact path="/" element={<Landing />} />
  //     <Route path="/dashboard" element={<Dashboard />} />
  //   </Routes>

  // );

  // return (
  //   <div>
  //     <Routes>

  //       <React.Fragment>

  //         <AuthRoutes exact path='/'>
  //           <Landing />
  //         </AuthRoutes>

  //         <ProtectedRoutes exact path='/dashboard'>
  //           <Dashboard />
  //         </ProtectedRoutes>

  //       </React.Fragment>
  //     </Routes>

  //   </div>)
  // return (
  //   <><Landing /></>
  //   // <><AboutUs /></>
  //   // <><HowItWorks /></>
  //   // <> <ReferralProgram /> </>
  //   // <> <TOS /> </>
  //   // <> <PrivacyPolicy /> </>
  //   // <> <Login /> </>
  //   // <> <SignUp /> </>
  //   // <> <ForgotPassword /> </>
  //   // <> <ContactUs /> </>
  //   // <> <Dashboard /> </>
  //   // <> <DepositList /> </>
  //   // <> <Payment /> </>
  //   // <> <Process /> </>
  //   // <> <History /> </>
  //   // <> <MyAccount /> </>

  //   // <><Withdraw /></>

  //   // <> <FAQS /> </>

  //   // <><Referral /></>

  // );
};

function NoMatch() {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2 style={{ color: "red" }}> Not allowed </h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function FallBack() {
  // const { t, i18n } = useTranslation();
  // return <h1>{t('Welcome to React')}</h1>
}

export default App;
